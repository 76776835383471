function FourZeroFour() {
  return (
    <>
      <div className="w-screen h-sceen">
        404
      </div>
    </>
  );
}

export default FourZeroFour;
